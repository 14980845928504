import L, { LatLngTuple } from "leaflet";
import { Marker, Popup } from "react-leaflet";

export interface AgentMarkerProp {
  position: LatLngTuple;
  mrn: string;
  onClick?: (event: any) => void;
}

export const AgentMarker = ({ mrn, position, onClick }: AgentMarkerProp) => {
  const svgIcon = L.divIcon({
    html: `
          <svg
  class="_17-ship-passenger"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M7.53335 10.0001L7.03335 11H2.53761C2.10246 11 1.875 11.5174 2.16916 11.838L4.84156 15.6723C4.97979 15.6049 5.11561 15.5348 5.24903 15.462C5.41698 15.3707 5.57307 15.2583 5.7173 15.125C5.90961 14.975 6.11794 14.9 6.3423 14.9C6.56666 14.9 6.77499 14.975 6.9673 15.125C7.28781 15.3917 7.64037 15.604 8.02499 15.762C8.4096 15.9207 8.81025 16 9.22691 16C9.64358 16 10.0442 15.9207 10.4288 15.762C10.8134 15.604 11.166 15.3917 11.4865 15.125C11.6788 14.975 11.8872 14.9 12.1115 14.9C12.3359 14.9 12.5442 14.975 12.7365 15.125C13.057 15.3917 13.4096 15.604 13.7942 15.762C14.1788 15.9207 14.5795 16 14.9961 16C15.4128 16 15.8134 15.9207 16.1981 15.762C16.5827 15.604 16.9352 15.3917 17.2558 15.125C17.4481 14.975 17.6564 14.9 17.8808 14.9C18.1051 14.9 18.3134 14.975 18.5058 15.125C18.65 15.2583 18.8064 15.3707 18.975 15.462C19.1429 15.554 19.3151 15.6417 19.4913 15.725C19.8625 15.879 20.5361 15.9129 21.109 15.844L22.07 12L21.07 10.0001L18.57 10L18.07 9.0001H17.0333L16.57 8H11.07L10.5333 9L9.03335 9.0001L8.53335 10.0001L7.53335 10.0001ZM10.0333 11.0001H12.0333V12.0001H10.0333V11.0001ZM13.0333 11.0001H15.0333V12.0001H13.0333V11.0001ZM18.0334 11.0001H16.0334V12.0001H18.0334V11.0001Z"
    fill="#1A1A1A"
  />
  <path
    d="M4.525 19.612C4.025 19.7373 3.51667 19.8 3 19.8C2.71667 19.8 2.47933 19.704 2.288 19.512C2.096 19.3207 2 19.0833 2 18.8C2 18.5167 2.096 18.2793 2.288 18.088C2.47933 17.896 2.71667 17.8 3 17.8C3.43333 17.8 3.86267 17.7457 4.288 17.637C4.71267 17.529 5.11667 17.3583 5.5 17.125C5.65 17.0417 5.81667 17 6 17C6.18333 17 6.35 17.0417 6.5 17.125C6.88333 17.3583 7.28767 17.529 7.713 17.637C8.13767 17.7457 8.56667 17.8 9 17.8C9.43333 17.8 9.86267 17.7457 10.288 17.637C10.7127 17.529 11.1167 17.3583 11.5 17.125C11.65 17.0417 11.821 17 12.013 17C12.2043 17 12.3667 17.0417 12.5 17.125C12.8833 17.3583 13.2877 17.529 13.713 17.637C14.1377 17.7457 14.5667 17.8 15 17.8C15.4333 17.8 15.8627 17.7457 16.288 17.637C16.7127 17.529 17.1167 17.3583 17.5 17.125C17.6333 17.0417 17.8 17 18 17C18.2 17 18.3667 17.0417 18.5 17.125C18.8833 17.3583 19.2873 17.529 19.712 17.637C20.1373 17.7457 20.5667 17.8 21 17.8C21.2833 17.8 21.5207 17.896 21.712 18.088C21.904 18.2793 22 18.5167 22 18.8C22 19.0833 21.904 19.3207 21.712 19.512C21.5207 19.704 21.2833 19.8 21 19.8C20.4833 19.8 19.975 19.7373 19.475 19.612C18.975 19.4873 18.4833 19.3 18 19.05C17.5167 19.3 17.025 19.4873 16.525 19.612C16.025 19.7373 15.5167 19.8 15 19.8C14.4833 19.8 13.975 19.7373 13.475 19.612C12.975 19.4873 12.4833 19.3 12 19.05C11.5167 19.3 11.025 19.4873 10.525 19.612C10.025 19.7373 9.51667 19.8 9 19.8C8.48333 19.8 7.975 19.7373 7.475 19.612C6.975 19.4873 6.48333 19.3 6 19.05C5.51667 19.3 5.025 19.4873 4.525 19.612Z"
    fill="#1A1A1A"
  />
</svg>
`,
    className: "svg-icon",
    iconSize: [24, 40],
    iconAnchor: [12, 10],
  });

  return (
    <Marker position={position} icon={svgIcon}>
      <Popup><a href="#" onClick={onClick}>{mrn}</a></Popup>
    </Marker>
  );
};
